
@font-face {
    font-family: 'transtext';
    font-style: italic;
    font-weight: 700;
    src: url(/fonts/transtext-bd_bold_italic-webfont.eot);
    src: url(/fonts/transtext-bd_bold_italic-webfont.eot#iefix) format("embedded-opentype"),url(/fonts/transtext-bd_bold_italic-webfont.woff) format("woff"),url(/fonts/transtext-bd_bold_italic-webfont.ttf) format("truetype"),url(/fonts/transtext-bd_bold_italic-webfont.svg#transtext-bd_bold_italic-webfont) format("svg")
}

@font-face {
    font-family: 'transtext';
    font-weight: 700;
    src: url(/fonts/transtext-bd_bold-webfont.eot);
    src: url(/fonts/transtext-bd_bold-webfont.eot#iefix) format("embedded-opentype"),url(/fonts/transtext-bd_bold-webfont.woff) format("woff"),url(/fonts/transtext-bd_bold-webfont.ttf) format("truetype"),url(/fonts/transtext-bd_bold-webfont.svg#transtext-bd_bold-webfont) format("svg")
}

@font-face {
    font-family: 'transtext';
    font-weight: 300;
    font-style: italic;
    src: url(/fonts/transtext-lt_light_italic-webfont.eot);
    src: url(/fonts/transtext-lt_light_italic-webfont.eot#iefix) format("embedded-opentype"),url(/fonts/transtext-lt_light_italic-webfont.woff) format("woff"),url(/fonts/transtext-lt_light_italic-webfont.ttf) format("truetype"),url(/fonts/transtext-lt_light_italic-webfont.svg#transtext-lt_light_italic-webfont) format("svg")
}

@font-face {
    font-family: 'transtext';
    font-weight: 300;
    src: url(/fonts/transtext-lt_light-webfont.eot);
    src: url(/fonts/transtext-lt_light-webfont.eot#iefix) format("embedded-opentype"),url(/fonts/transtext-lt_light-webfont.woff) format("woff"),url(/fonts/transtext-lt_light-webfont.ttf) format("truetype"),url(/fonts/transtext-lt_light-webfont.svg#transtext-lt_light-webfont) format("svg")
}

@font-face {
    font-family: 'transtext';
    font-weight: 500;
    font-style: italic;
    src: url(/fonts/transtext-md_medium_italic-webfont.eot);
    src: url(/fonts/transtext-md_medium_italic-webfont.eot#iefix) format("embedded-opentype"),url(/fonts/transtext-md_medium_italic-webfont.woff) format("woff"),url(/fonts/transtext-md_medium_italic-webfont.ttf) format("truetype"),url(/fonts/transtext-md_medium_italic-webfont.svg#transtext-md_medium_italic-webfont) format("svg")
}

@font-face {
    font-family: 'transtext';
    font-weight: 500;
    src: url(/fonts/transtext-md_medium-webfont.eot);
    src: url(/fonts/transtext-md_medium-webfont.eot#iefix) format("embedded-opentype"),url(/fonts/transtext-md_medium-webfont.woff) format("woff"),url(/fonts/transtext-md_medium-webfont.ttf) format("truetype"),url(/fonts/transtext-md_medium-webfont.svg#transtext-md_medium-webfont) format("svg")
}

@font-face {
    font-family: 'transtext';
    font-weight: 400;
    font-style: italic;
    src: url(/fonts/transtext-rm_italic-webfont.eot);
    src: url(/fonts/transtext-rm_italic-webfont.eot#iefix) format("embedded-opentype"),url(/fonts/transtext-rm_italic-webfont.woff) format("woff"),url(/fonts/transtext-rm_italic-webfont.ttf) format("truetype"),url(/fonts/transtext-rm_italic-webfont.svg#transtext-rm_italic-webfont) format("svg")
}

@font-face {
    font-family: 'transtext';
    font-weight: 400;
    src: url(/fonts/transtext-rm_roman-webfont.eot);
    src: url(/fonts/transtext-rm_roman-webfont.eot#iefix) format("embedded-opentype"),url(/fonts/transtext-rm_roman-webfont.woff) format("woff"),url(/fonts/transtext-rm_roman-webfont.ttf) format("truetype"),url(/fonts/transtext-rm_roman-webfont.svg#transtext-rm_roman-webfont) format("svg")
}