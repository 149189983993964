$color-1: #E65010;
$color-2: #FAFAFA;
$color-3: #000000;
$color-4: #3C3C3B;
$color-5: #FCDDC0;
$color-6: #FCEB89;
$color-7: #DADADA;
$color-8: #732A82;
$color-9: #EADEEC;

$occ-green: #00A551;
$occ-orange: #F6921E;
$occ-red: #EC1C24;

.spi {
    font-family: frank-new, sans-serif;
    font-weight: 400;
    font-style: normal;
    box-sizing: border-box;
    background: $color-2;
}

.spi-dual-screen,
.spi-single-screen{
    display: flex;
    flex-direction: row;

    height: 100%;
}

.spi.spi-dual-screen .spi-landscape,
.spi.spi-single-screen .spi-landscape {
    flex: 1 0 0%;
    height: 100%;
    flex-direction: column;
    display: flex;
    min-width: 0;
}

.spi-header {
    background-color: $color-1;
    height: 150px;
    width: 100%;
}

.spi-header-wrapper {
    height: 150px;
    
    padding-left: 80px;
    padding-right: 80px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.spi-header-service {
    color: $color-2;
    line-height: 70px;
    font-size: 94px;
    height: 70px; // 1.343
    font-weight: 400;

    flex: 1;
}

.spi-time-now {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 70px;
}

.spi-time-now-label {
    color: $color-2;
    line-height: 45px;
    font-size: 60px;
    height: 45px;
    font-weight: 400;
    margin-right: 30px;
}

.spi-time-now-time {
    color: $color-2;
    line-height: 70px;
    font-size: 94px;
    height: 70px;
    font-weight: 400;
}



/*
HEADSIGN
*/

.spi-headsign {
    width: 100%;
}

.spi-headsign-wrapper {    
    padding-left: 80px;
    padding-right: 80px;

    padding-top: 35px;
    // padding-bottom: 40px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.spi-headsign-mode-icon img {
    width: 200px;
    height: 200px;
}

.spi-split-screen-headsign-mode-icon {
    display: none;
}

.spi-headsign-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 200px;
    margin-left: 50px;
}

.spi-headsign-text .spi-headsign-intercity-time {    
    font-size: 107px;
    line-height: 80px;
    font-weight: 500;
}

.spi-headsign-text .spi-headsign-intercity-destination {    
    font-size: 114px;
    line-height: 85px;
    font-weight: 500;
}

.spi-headsign-text .spi-headsign-intercity-waypoint {
    // margin-top: 20px;
    font-size: 81px;
    line-height: 60px;
}

.spi-headsign-text .spi-headsign-destination {    
    font-size: 134px;
    line-height: 100px;
    font-weight: 500;
}

.spi-headsign-text .spi-headsign-waypoint {    
    font-size: 74px;
    line-height: 55px;
    font-weight: 400;
}

.spi-headsign-platform {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 200px;
}

.spi-headsign-platform .spi-headsign-platform-label {
    font-size: 60px;
    line-height: 45px;
    font-weight: 400;
}

.spi-headsign-platform .spi-headsign-platform-number {
    font-size: 154px;
    line-height: 115px;
    font-weight: 500;
}

.spi-headsign-badge {
    display: none;
}

.spi-route-badge {
    height: 200px;
    width: 200px;
    border-radius: 10%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.spi-route-badge-wrapper {
    font-size: 134px;
    line-height: 100px;
    font-weight: 500;
}

.spi-route-badge-len-3 .spi-route-badge-wrapper{
    font-size: 94px;
    line-height: 70px;
    font-weight: 500;
}

.spi-route-badge-len-4 .spi-route-badge-wrapper{
    font-size: 81px;
    line-height: 60px;
    font-weight: 500;
}

/*
OCCUPANCY
*/

.spi-occupancy {
    // width: 980px;
}

.spi-occupancy-wrapper {    
    padding-left: 80px;
    padding-right: 80px;

    // padding-top: 35px;
    // padding-bottom: 40px;

    width: 980px;

    display: flex;
    flex-direction: column;
}

.spi-occupancy-car-numbers,
.spi-occupancy-cars {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.spi-occupancy-car-numbers {
    margin-top: 35px;
}

.spi-occupancy-cars {
    // margin-bottom: 30px;
}

.spi-occupancy-cars .car {
    flex: 1;
    max-width: 114px;
    height: 65px;
    background-color: $occ-green;
    border-radius: 8px;
    margin-left: 4px;
    margin-right: 4px;
}

.spi-occupancy-cars .car.orange {
    background-color: $occ-orange;
}
.spi-occupancy-cars .car.red {
    background-color: $occ-red;
}

.spi-occupancy-cars .car:last-child {
    border-top-right-radius: 57px;
}

.spi-occupancy-car-numbers .car-number {
    flex: 1;
    max-width: 114px;
    font-size: 40px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 500;
    margin-left: 4px;
    margin-right: 4px;
}

.spi-occupancy-cars .car:first-child,
.spi-occupancy-car-numbers .car-number:first-child {
    margin-left: 0;
}

.spi-occupancy-cars .car:last-child,
.spi-occupancy-car-numbers .car-number:last-child {
    margin-right: 0;
}

.spi-occupancy-car-numbers .car-number:last-child {
    // width: 64px;
    // margin-right: 50px;
}

/*
DIVIDER
*/

.spi-divider {
    height: 8px;
    background-color: $color-3;
    width: 100%;
    margin-top: -4px;
    margin-bottom: -4px;
}

.spi-container {
    width: 100%;
}

.spi-container-wrapper {    
    padding-left: 80px;
    padding-right: 80px;
}

.spi-container.spi-container-split > .spi-container-wrapper {
    display: flex;
    flex-direction: row;
    padding: 0;
    height: 100%;
}

/*
DEPARTURE
*/


.spi-departure {
    flex: 1;
    display: flex;

    padding-left: 80px;
    padding-right: 80px;

    padding-top: 37px;
    padding-bottom: 60px;

    overflow: hidden;
    flex-direction: row;
}

// .spi-departure-wrapper {
//     padding-left: 80px;
//     padding-right: 80px;

//     padding-top: 37px;
//     padding-bottom: 60px;

//     flex: 1;
//     display: flex;
//     overflow: hidden;
// }

/*
STOPPING PATTERN
*/

.spi-scrolling-stopping-pattern {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.spi-scrolling-stopping-pattern-wrapper {
    // padding-left: 80px;
    // padding-right: 80px;

    flex: 1;
    overflow: hidden;
}

.spi-scrolling-stopping-pattern-abs{
    // position: absolute;
    overflow: hidden;
}

.spi-stopping-pattern-stop {
    font-weight: 400;
    height: 112px;
    font-size: 101px;
    line-height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .airport-icon {
        height: 65px;
        width: 65px;

        margin-left: 20px;
    }
}

.spi-stopping-pattern-stop div {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.spi-stopping-pattern-stop .spi-stopping-pattern-cars {
    font-size: 50%;
    line-height: 50%;
    min-width: 185px;

    width: 185px;
    height: 65px;
    background-color: $color-4;
    color: $color-2;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    margin-left: 20px;
}

.spi-landscape .spi-stopping-pattern-stop .spi-stopping-pattern-cars {
    margin-left: 40px;
    width: 215px;
    height: 75px;
    font-size: 47px;
}

.spi-stopping-pattern-stop .spi-stopping-pattern-walk {
    background-color: $color-7;
    font-size: 50%;
    line-height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;

    border-radius: 10px;
    margin-left: 20px;
    padding: 5px 10px;
}

.spi-stopping-pattern-stop .spi-stopping-pattern-walk img {
    height: 50px;
    width: 50px;
    margin-right: 10px;
}

.spi-label {
    width: 300px;
    height: 65px;
    background-color: $color-4;
    color: $color-2;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 15px;
}

.spi-label-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    
    font-size: 40px;
    line-height: 30px;

    font-weight: 400;
}


@keyframes spi-stopping-pattern-scroll {
    0%   { transform: translateY(0%); }
    100% { transform: translateY(-50%); }
}

/*
Departure RIGHT
*/

.spi-departure-right {
    display: flex;
}

.spi-departure-right-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    flex: 1;
}

.spi-departure-countdown{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.spi-departure-countdown-label {
    font-size: 60px;
    line-height: 45px;
    margin-bottom: 40px;
    font-weight: 400;
}

.spi-departure-countdown-time {
    font-size: 154px;
    line-height: 115px;
    font-weight: 500;

    white-space: nowrap;
}

.spi-departure-platform{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background: $color-2;
}

.spi-departure-platform-label {
    font-size: 60px;
    line-height: 45px;
    margin-bottom: 40px;
    font-weight: 400;
}

.spi-departure-platform-number {
    font-size: 154px;
    line-height: 115px;
    font-weight: 500;

    white-space: nowrap;
}

.spi-departure-platform-stop-code {
    font-size: 60px;
    line-height: 45px;
    margin-bottom: 40px;
    font-weight: 500;

    white-space: nowrap;
}

/*
CSM
*/

.spi-csm {
    background-color: $color-6;
    height: 100%;
    width: 100%;

    display: flex;
}

.spi-csm-wrapper {
    padding-left: 100px;
    padding-right: 100px;

    padding-top: 80px;
    padding-bottom: 80px;

    flex: 1;
    overflow: hidden;

    display: flex;
    flex-direction: column;
}

.spi-csm-abs{
    // position: absolute;
    overflow: hidden;
    flex: 1;
}

.spi-csm h1 {
    margin: 0;
    padding: 0;

    font-size: 121px;
    line-height: 90px;
    
    font-weight: 500;
}

.spi-csm-header {
    margin-bottom: 55px;
}

.spi-csm-body {
    flex: 1;
    font-size: 87px;
    line-height: 120px;
    overflow: hidden;
}

.spi-csm-body p {
    margin: 0;
    padding: 0;
    margin-bottom: 60px;
}

.spi-csm-tni {
    margin-top: 20px;
    font-size: 56px;
    line-height: 42px;
}

.spi-csm-body-content {
    margin-bottom: 200px;
}


/*
CSM Banner
*/

.spi-banner {
    background-color: $color-6;
    display: flex;
}

.spi-banner-wrapper {
    padding-left: 80px;
    padding-right: 80px;

    padding-top: 10px;
    padding-bottom: 10px;

    flex: 1;
    overflow: hidden;

    display: flex;
    flex-direction: column;
}

.spi-banner-text {
    font-size: 56px;
}




.spi-container-split {
    .spi-landscape {
        // margin-left: -10px;
        // margin-right: -10px;
    }

    .spi-landscape:first-child {
        margin-left: 0;
        // margin-right: -20px;
        border-right: 4px solid black;
    }

    .spi-landscape:last-child {
        // margin-left: -20px;
        margin-right: 0;
        border-left: 4px solid black;
    }

    .spi-headsign-wrapper {
        padding-left: 64px;
        padding-right: 64px;
        padding-top: 28px
    }

    .spi-container-wrapper,
    .spi-departure {
        padding-left: 64px;
        padding-right: 64px;
    }

    .spi-departure-location {
        margin-left: 20px;
        line-height: 55px;
        font-size: 74px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .spi-departure-platform {
            font-size: 54px;
        }
    }

    .spi-stopping-pattern-stop {
        // line-height: 55px;
        // font-size: 74px;
        // height: 90px;

        line-height: 44px;
        font-size: 59px;
        height: 72px;
    }

    .spi-departure-countdown-label {
        line-height: 40px;
        font-size: 54px;
    }

    .spi-departure-countdown-time {
        line-height: 65px;
        font-size: 87px;
    }

    .spi-headsign-destination {
        line-height: 56px;
        font-size: 75px;
    }

    .spi-departure-right-wrapper {
        position: relative;
        margin-left: -1000px;
    }

    .spi-departure-platform-label {
        font-size: 54px;
        line-height: 40px;
    }

    .spi-departure-platform-number {
        font-size: 87px;
        line-height: 65px;
    }

    .spi-headsign-badge {
        display: block;
    }

    .spi-label {
        height: 64px;
        width: 372px;
    }

    .spi-label-wrapper {
        font-size: 43px;
    }
    
    &.next-services {
        .spi-headsign-mode-icon img {
            display: none;
        }

        .spi-split-screen-headsign-mode-icon {
            display: inline-block !important;
        }
    
        .spi-split-screen-headsign-mode-icon img {
            // width: 120px;
            // height: 120px;
            // margin-right: 30px;
            width: 96px;
            height: 96px;
            margin-right: 24px;
        }

        .spi-route-badge {
            margin-right: 30px;
        }

        .spi-headsign-text {
            margin-left: 0;
        }

        .spi-headsign-text {
            height: 160px;
            justify-content: space-around;
        }

        .spi-headsign-text-intercity.spi-headsign-text {
            // height: 330px;
            height: 264px;
            justify-content: space-between;
        }

        .spi-headsign-waypoint,
        .spi-headsign-intercity-waypoint {
            font-size: 60px;
            line-height: 45px;
        }
    }

    &.alternative-services {
        .spi-headsign-mode-icon img {
            width: 120px;
            height: 120px;
        }

        .spi-headsign-text {
            height: 160px;
        }
    }

    .spi-precinct-departures .platform {
        width: 100px;
    }

    .spi-route-badge {
        height: 128px;
        width: 128px;
        border-radius: 10%;
    
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .spi-route-badge-wrapper {
        font-size: 75px;
        line-height: 56px;
        font-weight: 500;
    }
    
    .spi-route-badge-len-3 .spi-route-badge-wrapper{
        font-size: 65px;
        line-height: 48px;
        font-weight: 500;
    }
    
    .spi-route-badge-len-4 .spi-route-badge-wrapper{
        font-size: 54px;
        line-height: 40px;
        font-weight: 500;
    }

    .spi-departure-countdown {
        background-color: $color-2;
    }

    .spi-departure {
        padding-bottom: 37px;
    }

    .spi-headsign-intercity-destination {
        // margin-top: 20px;
        // font-size: 94px;
        // line-height: 70px;
        font-size: 75px;
        line-height: 56px;
    }

    .spi-headsign-intercity-time {
        display: flex;
        align-items: center;
        // font-size: 134px;
        // line-height: 100px;
        font-size: 107px;
        line-height: 80px;
    }
}


.spi-alt-departure-location {
    background-color: $color-7;
    font-size: 54px;
    line-height: 50px;

    padding: 10px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .spi-alt-departure-label {
        font-size: 40px;
        font-weight: 500;
    }

    .spi-alt-departure-platform {
        font-size: 40px;
    }
}


.spi-precinct-departures-header {
    .header {
        height: 96px;
        display: flex;
        flex-direction: row;
        border-bottom: 4px solid #000;
        margin: 0 15px;

        .header-item {
            font-size: 45px;
            font-weight: 500;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.spi-precinct-departures {
    .route{
        width: 120px;
    }

    .spi-precinct-departure-mode-icon img{
        height: 100px;
        width: 100px;
        display: block;
    }
    .service {
        flex: 1 1;
        min-width: 0;

        div.headline {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            // padding: 20px 0;
            margin: -8px 0;
        }

        .subtitle-and-mini-capacity {
            display: flex;
            flex-direction: row;
            min-width: 0;
            flex: 1 1;

            .subtitle-wrapper {
                display: flex;
                flex: 1 1;
                min-width: 0;
            }
        }

        div.subtitle {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding: 15px 0;
            margin: -15px 0;
            font-size: 46px;
            font-weight: 500;
        }
    }

    .platform {
        width: 200px;
        margin-left: -25px;
        margin-right: -25px;
        text-align: center;
    }

    .departs {
        width: 270px;
        text-align: right;
        white-space: nowrap;
        margin-right: 30px;
        // width: 300px;
        // margin-right: 0;
        // text-align: right;
        // white-space: nowrap;
    }

    .capacity {
        margin-left: 40px;
        width: 220px;
        text-align: right;
    }

    .anytrip-blink {
        -webkit-animation: blinker 2s linear infinite;
        animation: blinker 2s linear infinite;
    }

    .realtime-icon {
        display: inline-block;
        margin: -60px 0 0 -48px;
        vertical-align: top;
        height: 48px;
        width: 48px;
    }

    .route-badge {
        height: 100px;
        width: 100px;
        border-radius: 12px;
        background: transparent;
        color: white;
        font-weight: 500;
        /* 88px for cap X = 1.25 x 88 = 110 */
        font-size: 36px;
        
        text-align: center;
        line-height: 100px;
    }
    
    .route-badge.route-len-3 {
        font-size: 40px;
    }
    
    .route-badge.route-len-2 {
        font-size: 70px;
    }
    
    .route-badge.route-len-1 {
        font-size: 80px;
    }
}

.spi-precinct-departure-item {
    // margin-left: -80px;
    // margin-right: -80px;

    // padding-left: 80px;
    // padding-right: 80px;

    .display {
        min-height: 164px;
        flex: 1 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 0;
        margin: 0 15px;

        .display-item {
            font-size: 64px;
            font-weight: 500;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        .display-item.capacity {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
        }
    }
}

.spi-precinct-departure-item.even {
    background-color: $color-5;
}

@keyframes blinker {
    0% {
      opacity: 1;
    }
  
    50% {
      opacity: 1;
    }
  
    75% {
      opacity: 0;
    }
  }
  