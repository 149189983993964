html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  background-color: #000;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.scaled-wrapper-root {
}

.scaled-wrapper {
  background-color: #FFF;
  transform-origin: left top;
  position: absolute;
  overflow: hidden;
}


.screen-ready{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  font-size: 10vh;
  color: white;
  font-family: transtext, sans-serif;
}